import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { ServiceCategory } from './service-category.type';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { StrapiCollectionResponse, StrapiQueryParams } from '@/shared/types/strapi.types';
import { addingParameters } from '@/shared/lib/adding-parameters';

export const serviceCategoryApi = createApi({
  reducerPath: 'serviceCategoryApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.serviceCategories),

  endpoints: (builder) => ({
    getServicesCategory: builder.query<StrapiCollectionResponse<ServiceCategory>, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetServicesCategoryQuery } = serviceCategoryApi;
