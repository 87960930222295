import { FC, useEffect, useRef } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Tooltip as MantineTooltip, TooltipProps as MantineTooltipProps } from '@mantine/core';
import styles from './tooltip.module.scss';
import cn from 'classnames';

interface TooltipProps extends MantineTooltipProps {
  light?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({ className, label, light, children, ...props }) => {
  const [opened, { close, toggle }] = useDisclosure(false);
  const labelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (opened) {
      const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (labelRef.current && !labelRef.current.contains(event.target as Node)) {
          close();
        }
      };

      const handleScroll = () => {
        setTimeout(() => close(), 1000);
      };

      document.addEventListener('click', handleClickOutside);
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
      document.addEventListener('pointerdown', handleClickOutside);
      document.addEventListener('scroll', handleScroll);

      return () => {
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
        document.removeEventListener('pointerdown', handleClickOutside);
        document.removeEventListener('scroll', handleScroll);
      };
    }
  }, [close, opened]);

  return (
    <MantineTooltip
      events={{ hover: true, focus: false, touch: true }}
      classNames={{ tooltip: cn(styles.root, styles.tooltip, light && styles.light, className && className) }}
      withArrow
      label={<div ref={labelRef}>{label}</div>}
      arrowSize={6}
      arrowRadius={2}
      arrowOffset={16}
      width={335}
      multiline
      opened={opened}
      withinPortal
      position='top'
      {...props}
    >
      <div onClick={toggle}>{children}</div>
    </MantineTooltip>
  );
};
