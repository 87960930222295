export enum YM {
  code = 37649870,
  click_button_internet_tariff = 'click_button_internet_tariff',
  form_send_SHPD = 'form_send_SHPD',
  form_open_SHPD = 'form_open_SHPD',
  click_beautiful_number = 'click_button_buy_beauty',
  click_knopka_mobile = 'click_knopka_mobile',
  click_knopka_home_internet = 'click_knopka_home_internet',
  form_send_SHPD_main = 'form_send_SHPD_main',
  click_button_tariff = 'click_button_tariff',
  form_send_tariff_ = 'form_send_tariff_',
  form_open_tariff_ = 'form_open_tariff_',
  click_personal_account = 'click_lk',
  click_cart_salon = 'click_cart_salon',
  click_cart_dostavka = 'click_cart_dostavka',
  click_cart_next_step2 = 'click_cart_next_step2',
  click_cart_next_step3 = 'click_cart_next_step3',
  input_sms_pass_step4_success = 'input_sms_pass_step4_success',
  input_sms_pass_step4_failure = 'input_sms_pass_step4_failure',
  click_cart_step_final_main_page = 'click_cart_step_final_main_page',
  click_cart_step_final_tariff_page = 'click_cart_step_final_tariff_page',
  add_service = 'add_service',
  click_cart_mobile_tarif = 'click_cart_mobile_tarif',
  click_cart_home_internet = 'click_cart_home_internet',
}
