export enum LINKS {
  BEAUTIFUL_NUMBERS = 'https://volnamobile.ru/numbers/',
  VOLNA_SALE = 'https://volnamobile.ru/sale/',
  ESIM = 'https://volnamobile.ru/esim/',
  PERSONAL_ACCOUNT = 'https://lk-new.volnamobile.ru/',
  VK = 'https://vk.com/volnamobile',
  VK_SHARE_LINK = 'https://vk.com/share.php?url={url}',
  WHATSAPP_SHARE_LINK = 'https://wa.me/?text={url}',
  TELEGRAM_SHARE_LINK = 'https://t.me/share/url?url={url}',
  TELEGRAM_SKATE = 'https://t.me/volna_sk8',
  YOUTUBE = 'https://www.youtube.com/channel/UCBE0ytesPsF1BAbiRqMYTJw',
  TELEGRAM = 'https://t.me/Volnamobile_bot',
  DZEN = 'https://dzen.ru/volna_techno',
  HH = 'https://hh.ru/employer/10582846',
  GOOGLE_PLAY = 'https://redirect.appmetrica.yandex.com/serve/1180795317767977724',
  RU_STORE = 'https://redirect.appmetrica.yandex.com/serve/1180923242865509178',
  PAY = 'https://volnamobile.ru/pay/',
  HOME_INTERNET = 'https://volnamobile.ru/services/home/',
  AGREEMENT = 'https://volnamobile.ru/upload/iblock/a0b/o27jbd5igokijdvw7pn6xryuzr40xs1d/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%20%D0%9F%D0%94%20_2018-1_310522.pdf',
}

export const PathLabels = {
  main: 'Главная',
  services: {
    root: 'Услуги',
    all: 'Все услуги',
    mobile: 'Мобильная связь',
    categories: {
      popular: 'Самые популярные',
      mobile: 'Мобильная связь',
      homeInternet: 'Домашний интернет',
      fast: 'Быстрые команды',
      special: 'Подписки и сервисы',
    },
  },
  tariffMobile: {
    root: 'Мобильная связь',
    all: 'Все тарифы',
    archive: 'Архивные тарифы',
  },
  tariffHomeInternet: {
    root: 'Домашний интернет',
    all: 'Все тарифы',
  },
  aboutCompany: 'О компании',
  activation: 'SIM-карта с онлайн-активацией',
  faq: {
    root: 'Помощь и поддержка',
    search: 'Результаты поиска',
    app: 'Мобильное приложение',
    mobile: 'Мобильная связь',
  },
  skatepark: 'Скейт-парк',
  litres: 'Литрес',
  kaspersky: 'Касперский',
  beriVsye: 'Бери всё',
  sberbox: 'Аренда SberBox',
  viju: 'Подписка Viju',
};

export const Path = {
  main: '/',
  services: {
    root: '/services',
    popular: '/services/popular',
    mobile: '/services/mobile',
    homeInternet: '/services/home-internet',
    fast: '/services/fast',
    special: '/services/special',
    sberbox: '/services/sberbox',
  },
  tariffMobile: {
    root: '/tariffs',
    archive: '/tariffs/archive',
  },
  tariffHomeInternet: {
    root: '/home-internet',
  },
  news: '/news',
  tariffServices: 'tariff-services/activation',
  aboutCompany: '/about-company',
  faq: {
    root: '/faq',
    categories: '/faq/categories',
    questions: '/faq/questions',
    answer: '/faq/answer',
    searchResults: '/faq/search-results',
    app: '/faq/mobile-app',
    mobile: '/faq/categories/mobile',
  },
  replenishment: {
    root: '/replenishment',
  },
  pointsOfSale: '/points-of-sale',
  // esim: '/esim',
  skatepark: '/skatepark',
  litres: '/litres',
  notFound: '/404',
  kaspersky: '/kaspersky',
  viju: '/viju',
  beriVsye: '/beri-vsye',
};

export const apiEndpoints = {
  aboutCompany: 'api/about-company',
  articles: 'api/articles',
  beriVsye: 'api/beri-vsye',
  salesPoints: 'api/sales-points',
  services: 'api/services',
  serviceCategories: 'api/service-categories',
  serviceSubcategories: 'api/service-subcategories',
  serviceHomeInternets: 'api/service-home-internets',
  tariffs: 'api/tariffs',
  tariffHomeInternets: 'api/tariff-home-internets',
  tariffHomeInternetCategories: 'api/tariff-home-internet-categories',
  mainPage: 'api/main-page',
  precrm: 'api/precrm',
  orderProcessingService: 'api/order-processing',
  paymentAdapter: 'api/payment-adapter',
  shpdMatrix: 'api/shpd-matrix',
  faq: 'api/faqs',
  faqCategories: 'api/faq-categories',
  landings: 'api/landings',
  files: 'api/files',
  mobileApp: 'api/mobile-app',
  tariffFeatures: 'api/tariff-features',
};

export const BASE_URL = process.env.NEXT_PUBLIC_STRAPI_HOST;
