import { createApi } from '@reduxjs/toolkit/query/react';
import { CheckCodeArgs, CheckCodeResponse, SendSmsArg } from './sms-auth.type';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { apiEndpoints } from '@/shared/constants/links';

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.orderProcessingService + '/sms_auth'),
  endpoints: (builder) => ({
    sendSms: builder.mutation<CheckCodeResponse, SendSmsArg>({
      query: (data) => ({ url: '/send_sms', method: 'POST', body: data }),
    }),
    checkCode: builder.query<CheckCodeResponse, CheckCodeArgs>({
      query: (data) => `/check_code?contact_phone=${data.contact_phone}&sms_code=${data.sms_code}&source_id=${data.source_id}`,
    }),
  }),
});

export const { useSendSmsMutation, useCheckCodeQuery } = smsApi;
