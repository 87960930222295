export type populateParamsType = { [K: number | string]: string[] | string } | string | string[];

export function populateParams(params?: populateParamsType) {
  if (!params) {
    return [['populate', '*']];
  }
  if (typeof params === 'string') {
    return [['populate', params]];
  }
  if (Array.isArray(params)) {
    return [['populate', params.join()]];
  }
  const result: [string, string][] = [];
  for (const key in params) {
    const value = params[key];
    const valueTostring = Array.isArray(value) ? value.join() : value;
    result.push([`populate[${key}]`, valueTostring]);
  }

  return result;
}
