import styles from './modal.module.scss';
import { Modal as MantineModal, ModalProps as MantineModalProps } from '@mantine/core';
import cn from 'classnames';

interface ModalProps extends MantineModalProps {
  windowPadding?: string | number;
  contentClassName?: string;
  withoutCircle?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  windowPadding,
  contentClassName,
  children,
  withoutCircle,
  ...props
}) => {
  return (
    <MantineModal
      classNames={{
        root: styles.root,
        close: styles.close,
        content: cn(styles.content, contentClassName, { [styles.withoutCircle]: withoutCircle }),
        header: styles.header,
        title: styles.title,
        body: styles.body,
        inner: styles.inner,
      }}
      styles={{
        inner: {
          padding: windowPadding,
        },
      }}
      radius={24}
      centered
      {...props}
    >
      {children}
    </MantineModal>
  );
};
