export const plus7Theme = {
  colors: {
    primary: {
      900: '#282d3c',
      800: '#071c93',
      700: '#0c2ab7',
      600: '#1139db',
      500: '#184bff',
      400: '#517bff',
      300: '#7499ff',
      200: '#a2bdff',
      100: '#f4f6fb',
      0: '#ffffff',
    },
    base: {
      900: '#282D3C',
      800: '#414961',
      700: '#515B73',
      600: '#788095',
      500: '#8C94A8',
      400: '#BCC3D2',
      300: '#E2E7F3',
      200: '#ECF0F8',
      100: '#F4F6FB',
      0: '#FFFFFF',
    },
    accept: {
      garden: '#5CC867',
    },
    error: {
      fire: '#ED584E',
    },
    option: {
      dance: '#FD770D',
      ice: '#70D0F4',
      lime: '#B6D20D',
      flower: '#4F08E8',
      berrie: '#E75CA3',
      lima: '#75B91F',
      acai: '#92278F',
      sun: '#F8CC4D',
      drow: '#2C73B4',
      steel: '#ADACAC',
      crayola: '#BCC8E5',
    },
  },
};

export const volnaTheme = {
  colors: {
    primary: {
      900: '#282D3C',
      800: '#071C93',
      700: '#0C2AB7',
      600: '#1139DB',
      500: '#184BFF',
      400: '#517BFF',
      300: '#7499FF',
      200: '#A2BDFF',
      100: '#F4F6FB',
      0: '#FFFFFF',
    },
    base: {
      900: '#282D3C',
      800: '#414961',
      700: '#515B73',
      600: '#788095',
      500: '#8C94A8',
      400: '#BCC3D2',
      300: '#E2E7F3',
      200: '#ECF0F8',
      100: '#F4F6FB',
      0: '#E2E7F3',
    },
    accept: {
      garden: '#5CC867',
    },
    error: {
      fire: '#ED584E',
    },
    option: {
      dance: '#FD770D',
      ice: '#70D0F4',
      lime: '#B6D20D',
      flower: '#4F08E8',
      berrie: '#E75CA3',
      lima: '#75B91F',
      acai: '#92278F',
      sun: '#F8CC4D',
      drow: '#2C73B4',
      steel: '#ADACAC',
      crayola: '#BCC8E5',
    },
  },
};
