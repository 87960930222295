import { useCallback, useEffect, useRef, useState } from 'react';
import { SideBarOption, sidebarItems } from './constants';
import { useRouter } from 'next/router';

interface PrevOptions {
  name: string;
  options: SideBarOption[];
}

interface UseSidebarProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

export const useSidebar = ({ isOpen, onOpen, onClose }: UseSidebarProps) => {
  const [activeCategory, setActiveCategory] = useState(0);

  const [sidebarOptions, setSidebarOptions] = useState(sidebarItems);
  const prevSidebarState = useRef<PrevOptions[]>([]);
  const [activeItem, setActiveItem] = useState<null | number>(null);

  const { pathname } = useRouter();

  useEffect(() => {
    if (pathname === '/') {
      setActiveItem(null);
    }
  }, [pathname]);

  const handleChangeCategory = useCallback(
    (ind: number) => () => {
      setActiveCategory(ind);
    },
    [],
  );

  const handleOpenSideBar = useCallback(() => {
    onOpen();
  }, [isOpen]);

  const handleCloseSideBar = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.stopPropagation();
      onClose();
    },
    [isOpen],
  );

  const handleCloseByKeyboard = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [isOpen],
  );

  const handleGoInto = useCallback(
    (name: string, children: SideBarOption[] | null | undefined, id: number, src?: string) => () => {
      if (src && !children) {
        onClose();
      }
      if (children) {
        if (!isOpen) {
          handleOpenSideBar();
        } else {
          prevSidebarState.current.push({ name, options: sidebarOptions });
          setSidebarOptions(children);
        }
      }
      setActiveItem(id);
    },
    [prevSidebarState.current, sidebarOptions, isOpen],
  );

  const handleBackPrevState = useCallback(() => {
    if (!prevSidebarState.current.length) return;

    setSidebarOptions(prevSidebarState.current.at(-1)!.options);
    prevSidebarState.current.splice(-1);
  }, [prevSidebarState.current]);

  useEffect(() => {
    window.addEventListener('keydown', handleCloseByKeyboard);

    return () => {
      window.removeEventListener('keydown', handleCloseByKeyboard);
    };
  }, []);

  useEffect(() => {
    if (!isOpen && prevSidebarState.current) {
      setSidebarOptions(sidebarItems);
      prevSidebarState.current = [];
    }
  }, [isOpen, prevSidebarState.current]);

  return {
    activeItem,
    activeCategory,
    prevSidebarState,
    sidebarOptions,
    isFullSidebar: isOpen && !prevSidebarState.current.length,
    handleCloseSideBar,
    handleOpenSideBar,
    handleChangeCategory,
    handleBackPrevState,
    handleGoInto,
  };
};
