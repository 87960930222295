import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TariffCardOptionProps } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import { BUILDING_TYPE, ServiceHomeInternet } from '@/store/api/service-home-internet/service-home-internet.type';

export type ServiceItem = Omit<ServiceHomeInternet, 'tariffs' | 'background_image' | 'priority'> & {
  slider_value: number;
};
export type AdditionalServiceItem = Omit<ServiceHomeInternet, 'tariffs' | 'background_image' | 'priority'> & {
  added: boolean;
  background_image?: string;
};

const initialState = {
  status: {
    added: false,
    touched: false,
  },
  buildingType: BUILDING_TYPE.APARTMENT,
  speed: <ServiceItem>{},
  tv: <ServiceItem>{},
  optionsHomeInternet: <TariffCardOptionProps[]>[],
  homeInternetTouchedServices: <AdditionalServiceItem[]>[],
  basePriceHomeInternet: 0,
  totalPriceHomeInternet: 0,
  promotionBasePriceHomeInternet: <number | undefined | null>null,
  promotionTotalPriceHomeInternet: <number | undefined | null>null,
  shpd_trpl_id: <number | undefined | null>null,
  tariffName: <string | undefined | null>null,
};

export const HomeInternetSlice = createSlice({
  name: 'homeInternet',
  initialState,
  reducers: {
    changeHomeInternetTariffName: (state, action: PayloadAction<string>) => {
      state.tariffName = action.payload;
    },
    changeBuildingType: (state, action: PayloadAction<BUILDING_TYPE>) => {
      state.buildingType = action.payload;
    },
    changeSpeed: (state, action: PayloadAction<ServiceItem>) => {
      state.speed = action.payload;
    },
    changeTv: (state, action: PayloadAction<ServiceItem>) => {
      state.tv = action.payload;
    },
    changeOptionsHomeInternet: (state, action: PayloadAction<TariffCardOptionProps[]>) => {
      state.optionsHomeInternet = action.payload;
    },
    changeHomeInternetServices: (state, action: PayloadAction<AdditionalServiceItem[]>) => {
      state.homeInternetTouchedServices = action.payload;
    },
    changeBasePriceHomeInternet: (state, action: PayloadAction<number>) => {
      state.basePriceHomeInternet = action.payload;
    },
    changeTotalPriceHomeInternet: (state, action: PayloadAction<number>) => {
      state.totalPriceHomeInternet = action.payload;
    },
    changePromotionBasePriceHomeInternet: (state, action: PayloadAction<number | undefined | null>) => {
      state.promotionBasePriceHomeInternet = action.payload;
    },
    changePromotionTotalPriceHomeInternet: (state, action: PayloadAction<number | undefined | null>) => {
      state.promotionTotalPriceHomeInternet = action.payload;
    },
    changeShpdTrplId: (state, action: PayloadAction<number | undefined | null>) => {
      state.shpd_trpl_id = action.payload;
    },
    changeHomeInternetStatus: (state, action: PayloadAction<boolean>) => {
      state.status.added = action.payload;
      state.status.touched = true;
    },
    resetHomeInternetToInitial: (state) => {
      state.status = initialState.status;
      state.tariffName = initialState.tariffName;
      state.buildingType = initialState.buildingType;
      state.homeInternetTouchedServices = initialState.homeInternetTouchedServices;
      state.speed = initialState.speed;
      state.tv = initialState.tv;
      state.optionsHomeInternet = initialState.optionsHomeInternet;
      state.basePriceHomeInternet = initialState.basePriceHomeInternet;
      state.totalPriceHomeInternet = initialState.totalPriceHomeInternet;
      state.promotionBasePriceHomeInternet = initialState.promotionBasePriceHomeInternet;
      state.promotionTotalPriceHomeInternet = initialState.promotionTotalPriceHomeInternet;
      state.shpd_trpl_id = initialState.shpd_trpl_id;
    },
  },
});

export const {
  changeHomeInternetTariffName,
  changeBuildingType,
  changeSpeed,
  changeTv,
  changeOptionsHomeInternet,
  changeHomeInternetServices,
  changeBasePriceHomeInternet,
  changeTotalPriceHomeInternet,
  changePromotionBasePriceHomeInternet,
  changePromotionTotalPriceHomeInternet,
  changeShpdTrplId,
  changeHomeInternetStatus,
  resetHomeInternetToInitial,
} = HomeInternetSlice.actions;

export default HomeInternetSlice.reducer;
