import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isClient } from '@/shared/lib/utils';

export const baseQuery = (endpoint: string) => {
  return fetchBaseQuery({ baseUrl: endpoint });
};

export const getOrigin = () => {
  if (isClient()) {
    // console.log('window.location:', `${window.location.protocol}//${window.location.hostname}:${PORT}/`);
    // return `${window.location.protocol}//${window.location.hostname}/`;
    return `${window.location.origin}/`;
  } else {
    const HOST = process.env.NEXT_PUBLIC_NEXT_HOST || 'http://qa.volnamobile.ic8/';

    return HOST.endsWith('/') ? HOST : `${HOST}/`;
  }
};
