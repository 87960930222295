import { ReactNode, memo, useState } from 'react';
import { useRouter } from 'next/router';
import NavLink from 'next/link';
import cn from 'classnames';
import { LogoIcon, SearchIcon, PackIcon, UserIcon } from '@/shared/assets/svg';
import { LINKS } from '@/shared/constants/links';
// import { SearchInput } from '@/shared/ui-kit/search-input/search-input';
// import { useMediaQuery } from '@/shared/lib/use-media-query';
import Link from '@/shared/ui-kit/link/link';
import { YM } from '@/shared/constants/yandex-metrika';
import { reachGoal } from '@/shared/lib/ym';
import { NAVIGATION_LABELS, NAVIGATION_MENU_LIST, NAVIGATION_MAIN } from './constans';
import styles from './header.module.scss';

export const Header = memo(({ children }: { children: ReactNode }) => {
  const [openInput, setOpenInput] = useState(false);
  const router = useRouter();

  /*const onSearchInputFocus = () => {
    setOpenInput(true);
  };

  const onSearchInputLostFocus = () => {
    setOpenInput(false);
  };

  const { isMobile } = useMediaQuery();*/

  const onClick = (val: string) => {
    if (val === NAVIGATION_LABELS[NAVIGATION_MAIN.MOBILE]) {
      reachGoal(YM.click_knopka_mobile);
    } else if (val === NAVIGATION_LABELS[NAVIGATION_MAIN.HOME_INTERNET]) {
      reachGoal(YM.click_knopka_home_internet);
    }
  };
  
  const onPersonalAccountClick = () => {
    reachGoal(YM.click_personal_account);
  };

  return (
    <div className={styles.wrapper}>
      {children}
      <div className={styles.logo}>
        <NavLink href={'/'}>
          <LogoIcon />
        </NavLink>
      </div>
      <div className={openInput ? styles.navigationHiden : styles.navigation}>
        {NAVIGATION_MENU_LIST.map((list) => (
          <div key={list.id} onClick={() => onClick(list.label)}>
            <NavLink
              href={list.path}
              className={cn(styles.button, router.pathname.includes(list.path) && styles.button__activeLink)}
            >
              {list.label}
            </NavLink>
          </div>
        ))}
      </div>
      <div className={cn(styles.rightSide, openInput && styles.searchOpen)}>
        <Link href={LINKS.PERSONAL_ACCOUNT} className={styles.user} onClick={onPersonalAccountClick}>
          <UserIcon />
        </Link>
        <Link href={LINKS.PERSONAL_ACCOUNT} className={styles.personal} onClick={onPersonalAccountClick}>
          <PackIcon />
          <span className={styles.personal__label}>Личный кабинет</span>
        </Link>
      </div>
    </div>
  );
});
Header.displayName = 'Header';
