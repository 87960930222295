import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SliceBeautifulNumber {
  msisdn: string;
  phone_id: number;
  phone_type: string;
  price?: number;
  pht_id: number;
}

interface BeautifulNumberState {
  selectedNumber: SliceBeautifulNumber | null;
  lastSelected?: SliceBeautifulNumber | null;
  pinConfirmedNumber?: number | null;
}

const initialState: BeautifulNumberState = {
  selectedNumber: null,
  lastSelected: null,
  pinConfirmedNumber: null,
};

export const beautifulNumberSlice = createSlice({
  name: 'beautifulNumber',
  initialState,
  reducers: {
    setBeautifulNumber(state, action: PayloadAction<BeautifulNumberState>) {
      state.selectedNumber = action.payload.selectedNumber;
    },
    resetBeautifulNumber(state) {
      state.lastSelected = state.selectedNumber ? { ...state.selectedNumber } : null;
      state.selectedNumber = null;
    },
    setPinConfirmedNumber(state, action: PayloadAction<number | null>) {
      state.pinConfirmedNumber = action.payload;
    },
  },
});

export const {
  setBeautifulNumber,
  resetBeautifulNumber,
  setPinConfirmedNumber
} = beautifulNumberSlice.actions;

export default beautifulNumberSlice.reducer;
