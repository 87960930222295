import { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/router';

const useUtmParameters = () => {
  const router = useRouter();
  const [previousUtmTags, setPreviousUtmTags] = useState('');

  const setLocalStorageIfParamExists = useCallback(
    (paramName: string) => {
      const paramValue = router.query[paramName];
      if (paramValue) {
        localStorage.setItem(paramName, (paramName === 'utm_source' ? '?' : '&') + `${paramName}=${paramValue}`);
      }
    },
    [router.query],
  );

  const getUtmValue = (key: string) => {
    return localStorage.getItem(key) || '';
  };

  useEffect(() => {
    const paramsToCheck = ['utm_source', 'utm_campaign', 'utm_content', 'utm_medium', 'utm_term'];
    paramsToCheck.forEach((param) => setLocalStorageIfParamExists(param));

    const utmTags =
      getUtmValue('utm_source') +
      getUtmValue('utm_campaign') +
      getUtmValue('utm_content') +
      getUtmValue('utm_medium') +
      getUtmValue('utm_term');

    if (utmTags !== previousUtmTags) {
      window.history.pushState(utmTags, '', utmTags);
      localStorage.setItem('utmTags', utmTags);
      setPreviousUtmTags(utmTags);
    }
  }, [router.query, setLocalStorageIfParamExists, previousUtmTags]);

  return null;
};

export default useUtmParameters;
