import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { MainPageEntity } from '@/store/api/main-page/main-page.type';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { StrapiQueryParams, StrapiSingleTypeResponse } from '@/shared/types/strapi.types';
import { addingParameters } from '@/shared/lib/adding-parameters';

export const mainPageApi = createApi({
  reducerPath: 'mainPageApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.mainPage),

  endpoints: (builder) => ({
    getMainPage: builder.query<StrapiSingleTypeResponse<MainPageEntity>, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetMainPageQuery } = mainPageApi;
