import { StrapiQueryParams } from '../types/strapi.types';
import { populateParams, populateParamsType } from './populate';

type ParamValues = string | number | Array<string | number> | null | undefined | populateParamsType;

export const addingParameters = <T extends StrapiQueryParams>({ params, path }: { params: T; path?: string }) => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, val]) => {
    const value = val as ParamValues;

    if (key === 'populate') {
      const populate = populateParams(value as populateParamsType | undefined);
      for (const item of populate) {
        searchParams.append(item[0], item[1]);
      }
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        searchParams.append(key, String(item));
      });
    } else {
      searchParams.append(key, String(value));
    }
  });

  return path ? `${path}?${searchParams.toString()}` : `?${searchParams.toString()}`;
};
