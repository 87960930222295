import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { apiEndpoints } from '@/shared/constants/links';
import { storage } from '@/shared/lib/storage';
import {
  CreateOrderData,
  CreateOrderArgs,
  ReservationResponse,
  ReservationRequest,
} from './order.type';

export const orderProcessingApi = createApi({
  reducerPath: 'orderProcessingApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.orderProcessingService),
  endpoints: (builder) => ({
    createOrder: builder.mutation<CreateOrderData, CreateOrderArgs>({
      query: (data) => {
        const utmTags = storage.get('utmTags');

        return {
          url: `/orders/create${utmTags ? `${utmTags}` : ''}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    reservation: builder.mutation<ReservationResponse, ReservationRequest>({
      query: (data) => {
        return {
          url: '/orders/reservation',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useCreateOrderMutation, useReservationMutation } = orderProcessingApi;
