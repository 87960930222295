import { FC } from 'react';
import Markdown, { Options } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import cn from 'classnames';
import styles from './rich-text.module.scss';

interface RichTextProps extends Options {
  whiteText?: boolean;
  greyText?: boolean;
  html?: string;
}
export const RichText: FC<RichTextProps> = ({ whiteText, greyText, className, children, html, ...props }) => {
  const urlTransform = (url: string) => {
    return url;
  };

  if (html) {
    return <div className={cn(className)} dangerouslySetInnerHTML={{ __html: html }} />;
  };

  return (
    <Markdown
      urlTransform={urlTransform}
      remarkPlugins={[remarkGfm]}
      className={cn(
        whiteText ? styles.markdown__white : greyText ? styles.markdown__grey : styles.markdown,
        className && className,
      )}
      {...props}
    >
      {children}
    </Markdown>
  );
};
