import React, { FC, useState } from 'react';
import { Modal } from '@/shared/ui-kit';
import { CITY_LIST } from './constants';
import { LocationProps } from './location.types';
import styles from './location.module.scss';
// import { SearchInput } from '@/shared/ui-kit/search-input/search-input';
import { UnstyledButton } from '@mantine/core';

export const Location: FC<LocationProps> = ({ opened, onClose }) => {
  const [value, setValue] = useState('');

  const onClick = (value: string) => {
    setValue(value);
  };

  /*const handleChange = (value: string) => {
    setValue(value);
  };

  const handleInput = (value: string) => {
    setValue(value);
  };*/

  return (
    <Modal opened={opened} onClose={onClose}>
      <div className={styles.modal}>
        <div className={styles.title}>Выбор города</div>
        {/*
          Сейчас SearchInput выглядит забагованным и несовместимым с этим компонентом
          <SearchInput
            data={LOCATION_LIST}
            placeholder='Ваш город'
            className={styles.searchInputWrapper}
            value={value}
            onChange={handleChange}
            onInput={handleInput}
          />
        */}
        <div className={styles.links}>
          {CITY_LIST.map((city, i) => (
            <UnstyledButton className={styles.link} key={i} onClick={() => onClick(city)}>
              {city}
            </UnstyledButton>
          ))}
        </div>
      </div>
    </Modal>
  );
};
