import { FC } from 'react';
import cn from 'classnames';
import { Checkbox as CheckboxIcon } from '@/shared/assets/svg';
import styles from './checkbox.module.scss';

interface CheckboxProps {
  added?: boolean;
  className?: string;
  onClick?: () => void;
}
export const Checkbox: FC<CheckboxProps> = ({ added, className, onClick }) => {
  return (
    <div
      className={cn(styles.container, { [styles.container__added]: added }, className && className)}
      onClick={onClick}
    >
      {added && <CheckboxIcon />}
    </div>
  );
};
