import React, { FC } from 'react';
import { ArrowRight, SearchIcon } from '@/shared/assets/svg';
import { Autocomplete, CloseButton, ScrollArea } from '@mantine/core';
import cn from 'classnames';
import { useSearchInput } from './use-search-input';
import { useRouter } from 'next/router';
import { Path } from '@/shared/constants/links';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import styles from './search-input.module.scss';

interface SearchInputProps {
  data?: string[];
  placeholder?: string;
  onClose?: () => void;
  onClickOutside?: () => void;
  className?: string;
  value?: string;
  customRenderItems?: React.JSX.Element[];
  onChange?(value: string): void;
  onInput?(value: string): void;
  onEnterPress?(): void;
}

export const SearchInput: FC<SearchInputProps> = ({
  data = [],
  placeholder,
  className,
  value,
  customRenderItems,
  onClickOutside,
  onChange,
  onInput,
  onEnterPress,
}) => {
  const { isMobile } = useMediaQuery();
  const {
    value_,
    wrapperRef,
    showCloseButton,
    handleMouseEnter,
    handleMouseLeave,
    onClear,
    handleInput,
    handleChange,
    handleKeyUp,
  } = useSearchInput({ onClickOutside, value, onChange, onInput, onEnterPress });

  const router = useRouter();
  const mah = isMobile ? 'calc(100dvh - 350px)' : '400px';

  function renderDropdown() {
    return (
      <ScrollArea.Autosize
        w='calc(100% + 8px)'
        mah={mah}
        type='always'
        offsetScrollbars
        scrollbarSize='8px'
        classNames={{ scrollbar: styles.scroll__bar, thumb: styles.scroll__thumb, viewport: styles.scroll__viewport }}
      >
        <div className={styles.dropdown}>
          {customRenderItems}
          <button
            className={styles.btnAll}
            onClick={() => {
              router.push(`${Path.faq.searchResults}/?search=${value_}`);
            }}
          >
            Все результаты <ArrowRight className={styles.arrow} />
          </button>
        </div>
      </ScrollArea.Autosize>
    );
  }

  return (
    <div
      ref={wrapperRef}
      className={`${className} autocomplete-wrapper`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id='autocomplete-wrapper'
    >
      <Autocomplete
        classNames={{
          root: styles.input__root,
          input: cn(styles.input__input),
          label: styles.input__label,
          wrapper: styles.input__wrapper,
          dropdown: styles.input__dropdown,
          item: styles.input__item,
        }}
        rightSection={showCloseButton ? <CloseButton className={styles.closeIcon} onClick={onClear} /> : null}
        icon={<SearchIcon />}
        data={data}
        value={value_}
        placeholder={placeholder}
        onInput={handleInput}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        dropdownComponent={renderDropdown}
      />
    </div>
  );
};
