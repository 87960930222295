import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { BottomSheet as BSheet, BottomSheetProps as BSheetProps } from 'react-spring-bottom-sheet';
import { toggleWidgetVisibility } from '@/shared/lib/chat2desk-utils';
import styles from './bottom-sheet.module.scss';

export interface BottomSheetProps extends BSheetProps {}

export const BottomSheet: FC<BSheetProps> = ({ open, children, className, ...props }) => {
  useEffect(() => {
    if (open) {
      toggleWidgetVisibility(false);
    } else {
      toggleWidgetVisibility(true);
    }
  }, [open]);

  return (
    <BSheet open={open} {...props} className={classNames(styles.wrapper, className)}>
      {children}
    </BSheet>
  );
};
