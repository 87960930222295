import { FC, memo, useState } from 'react';
import { TextInput as MantineInput, InputProps as MantineInputProps } from '@mantine/core';
import { CloseIcon, SearchIcon } from '@/shared/assets/svg';
import { formatOnPaste, validate } from '@/shared/lib/validation';
import styles from './input.module.scss';
import cn from 'classnames';

interface InputProps extends MantineInputProps {
  onChange?: (unmasked: string, masked?: string) => void;
  darkBackground?: boolean;
  label?: string;
  mask?: string;
  placeholder?: string;
  initialValue?: string;
}

const Input: FC<InputProps> = memo(({ onChange: changeHandler, initialValue, ...props }) => {
  const [value, setValue] = useState(initialValue || '');
  const [isActive, setIsActive] = useState(false);

  const onChange = (val: string) => {
    let newValue = val;
    if (value === val.slice(0, -1)) {
      newValue = validate(val) ? val : val.slice(0, -1);
    } else {
      newValue = formatOnPaste(val);
    }

    changeHandler && changeHandler(newValue);
    setValue(newValue);
  };

  return (
    <MantineInput
      classNames={{ input: styles.input }}
      icon={<SearchIcon />}
      iconWidth={60}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      rightSection={
        value && (
          <button className={cn(styles.button, !isActive && styles.hidden)} type='button' onClick={() => onChange('')}>
            <CloseIcon />
          </button>
        )
      }
      rightSectionWidth={60}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      {...props}
    />
  );
});

Input.displayName = 'Input';
export { Input };
