export const cookies = [
  {
    id: 1,
    question: 'Что такое файлы cookie',
    answer:
      'Файлы cookie – текстовые файлы небольшого размера, которые сохраняются на вашем устройстве (персональном компьютере, ноутбуке, планшете, мобильном телефоне и т.п.), когда вы посещаете сайты в сети «Интернет». Кроме того, при посещении сайта ООО «КТК ТЕЛЕКОМ» по адресу volnamobile.ru в сети «Интернет» происходит автоматический сбор иных данных, в том числе: технических характеристик устройства, IP-адреса, информации об используемом браузере и языке, даты и времени доступа к сайту, адресов запрашиваемых страниц сайта и иной подобной информации.',
  },
  {
    id: 2,
    question: 'Какие виды файлов cookie используются',
    answer:
      'В зависимости от используемых вами браузера и устройства используются разные наборы файлов cookie, включающие в себя строго необходимые, эксплуатационные, функциональные и аналитические файлы cookie.',
  },
  {
    id: 3,
    question: 'Для чего могут использоваться файлы cookie',
    answer:
      'При посещении вами сайта ООО «КТК ТЕЛЕКОМ» по адресу volnamobile.ru в сети «Интернет» файлы cookie могут использоваться для: обеспечения функционирования и безопасности сайта; улучшения качества сайта; регистрации в системе самообслуживания (личном кабинете); предоставлении вам информации о ООО «КТК ТЕЛЕКОМ», его продуктах и услугах; усовершенствования продуктов и (или) услуг и для разработки новых продуктов и (или) услуг. Иная собираемая информация может быть использована для генерации вашего «списка интересов», состоящего из случайного идентификатора, категории интереса и отметки времени для демонстрации вам интернет-контента и рекламных объявлений, соответствующих вашим интересам.',
  },
  {
    id: 4,
    question: 'Как управлять файлами cookie',
    answer:
      'Используемые вами браузер и (или) устройство могут позволять вам блокировать, удалять или иным образом ограничивать использование файлов cookie. Но файлы cookie являются важной частью сайта ООО «КТК ТЕЛЕКОМ» по адресу volnamobile.ru в сети «Интернет», поэтому блокировка, удаление или ограничение их использования может привести к тому, что вы будете иметь доступ не ко всем функциям сайта. Чтобы узнать, как управлять файлами cookie с помощью используемых вами браузера или устройства, вы можете воспользоваться инструкцией, предоставляемой разработчиком браузера или производителем устройства.',
  },
];
