import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { ServiceHomeInternetEntity } from './service-home-internet.type';

export const serviceHomeInternetApi = createApi({
  reducerPath: 'serviceHomeInternetApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.serviceHomeInternets),

  endpoints: (builder) => ({
    getHomeInternetServices: builder.query<ServiceHomeInternetEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetHomeInternetServicesQuery } = serviceHomeInternetApi;
