import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { MobileAppEntity } from './mobile-app.type';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { StrapiQueryParams } from '@/shared/types/strapi.types';

export const mobileAppApi = createApi({
  reducerPath: 'mobileAppApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.mobileApp),

  endpoints: (builder) => ({
    getMobileApp: builder.query<MobileAppEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetMobileAppQuery } = mobileAppApi;