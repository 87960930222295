import { YM } from '@/shared/constants/yandex-metrika';
import { isClient } from '@/shared/lib/utils';

type ParamsType = Record<string, string | number | string[] | number[]>;

export function reachGoal(goal: string, params?: ParamsType) {
  if (isClient() && window.ym && typeof window.ym === 'function') {
    window.ym(YM.code, 'reachGoal', goal, params);
  }
}
