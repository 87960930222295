import { useEffect } from 'react';
import { fixButtonPosition } from '@/shared/lib/chat2desk-utils';
import { useAppSelector } from '@/store/hooks';
import { initialize } from './initialize';

export const Chat2Desk = () => {
  const isCookiesAccepted = useAppSelector((state) => state.app.isCookiesAccepted);

  useEffect(() => {
    if (isCookiesAccepted) {
      initialize();

      // Костыли с исправлением отступа снизу у кнопки виджета
      const handleInitializeWidget = () => {
        fixButtonPosition();
      };

      const handleResize = () => {
        setTimeout(() => {
          fixButtonPosition();
        }, 100);
      };

      document.addEventListener('initialize_widget', handleInitializeWidget);
      window.addEventListener('resize', handleResize);
      // Cleanup listeners on component unmount
      return () => {
        document.removeEventListener('initialize_widget', handleInitializeWidget);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isCookiesAccepted]);

  return null;
};
