import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { ServiceResponse } from '@/store/api/service/service.type';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { addingParameters } from '@/shared/lib/adding-parameters';
// import { addingParameters } from '@/shared/lib/adding-parameters';

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.services),

  endpoints: (builder) => ({
    getServices: builder.query<ServiceResponse, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetServicesQuery } = serviceApi;
