import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { Article, ArticleEntity } from '@/store/api/article/article.type';
import { StrapiQueryParams, StrapiCollectionResponse, StrapiSingleTypeResponse } from '@/shared/types/strapi.types';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { baseQuery, getOrigin } from "@/shared/lib/base-query";

/*
Так не работает!
const { publicRuntimeConfig } = getConfig();
const BASE_URL = publicRuntimeConfig.baseUrl;
*/

export const articleApi = createApi({
  reducerPath: 'articleApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.articles),

  endpoints: (builder) => ({
    getArticle: builder.query<StrapiSingleTypeResponse<Article>, number>({
      query: (id) => {
        return '/' + id + '?populate=main_picture,gallery';
      },
    }),
    getArticles: builder.query<ArticleEntity, StrapiQueryParams>({
      query: (params) => {
        const query = addingParameters({
          params,
        });

        return '/' + query;
      },
    }),
  }),
});

export const { useGetArticlesQuery, useGetArticleQuery } = articleApi;
