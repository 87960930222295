import { FC } from 'react';
import { Switch, SwitchProps } from '@mantine/core';
import styles from './switch-narrow.module.scss';

export const SwitchNarrow: FC<SwitchProps> = ({ ...props }) => {
  return (
    <Switch
      classNames={{
        root: styles.root,
        body: styles.body,
        track: styles.track,
        trackLabel: styles.trackLabel,
        input: styles.input,
        thumb: styles.thumb,
      }}
      {...props}
    />
  );
};
