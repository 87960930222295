import { LINKS, Path } from '@/shared/constants/links';

enum NAVIGATION_MAIN {
  MAIN,
  MOBILE,
  HOME_INTERNET,
  VOLNA_SALE,
  ESIM,
}

const NAVIGATION_PATHNAME = {
  [NAVIGATION_MAIN.MAIN]: '/',
  [NAVIGATION_MAIN.MOBILE]: Path.tariffMobile.root,
  [NAVIGATION_MAIN.HOME_INTERNET]: Path.tariffHomeInternet.root,
  [NAVIGATION_MAIN.VOLNA_SALE]: LINKS.VOLNA_SALE,
  [NAVIGATION_MAIN.ESIM]: 'https://volnamobile.ru/esim/',
  // [NAVIGATION_MAIN.ESIM]: Path.esim,
} as const;

const NAVIGATION_LABELS = {
  [NAVIGATION_MAIN.MOBILE]: 'Мобильная связь',
  [NAVIGATION_MAIN.HOME_INTERNET]: 'Домашний интернет',
  [NAVIGATION_MAIN.VOLNA_SALE]: 'Волна Sale',
  [NAVIGATION_MAIN.ESIM]: 'eSim',
} as const;

const NAVIGATION_MENU_LIST = [
  {
    id: NAVIGATION_MAIN.MOBILE,
    path: NAVIGATION_PATHNAME[NAVIGATION_MAIN.MOBILE],
    label: NAVIGATION_LABELS[NAVIGATION_MAIN.MOBILE],
  },
  {
    id: NAVIGATION_MAIN.HOME_INTERNET,
    path: NAVIGATION_PATHNAME[NAVIGATION_MAIN.HOME_INTERNET],
    label: NAVIGATION_LABELS[NAVIGATION_MAIN.HOME_INTERNET],
  },
  {
    id: NAVIGATION_MAIN.VOLNA_SALE,
    path: NAVIGATION_PATHNAME[NAVIGATION_MAIN.VOLNA_SALE],
    label: NAVIGATION_LABELS[NAVIGATION_MAIN.VOLNA_SALE],
  },
  {
    id: NAVIGATION_MAIN.ESIM,
    path: NAVIGATION_PATHNAME[NAVIGATION_MAIN.ESIM],
    label: NAVIGATION_LABELS[NAVIGATION_MAIN.ESIM],
  },
];

export { NAVIGATION_MAIN, NAVIGATION_PATHNAME, NAVIGATION_LABELS, NAVIGATION_MENU_LIST };
