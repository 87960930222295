import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { apiEndpoints } from '@/shared/constants/links';
import { preCrmCreateTicketData, preCrmCreateTicketArgs } from './ticket.type';

export const preCrmTicketApi = createApi({
  reducerPath: 'preCrmCreateTicketApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.precrm),
  endpoints: (builder) => ({
    preCrmCreateTicket: builder.mutation<preCrmCreateTicketData, preCrmCreateTicketArgs>({
      query: (data) => ({
        url: '/new_ticket',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { usePreCrmCreateTicketMutation } = preCrmTicketApi;
