import { VkIcon, TelegramIcon, YouTubeIcon, DzenIcon, PCLogo } from '@/shared/assets/svg';
import { Button } from '@mantine/core';
import Link from 'next/link';
import { LINKS } from '@/shared/constants/links';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { useRouter } from 'next/router';
import { Path } from '@/shared/constants/links';
import Image from 'next/image';
import cn from 'classnames';
import { memo } from 'react';
import styles from './footer-cards.module.scss';

export const FooterCardsBlock = memo(() => {
  const { isMobile, isDesktopMax } = useMediaQuery();
  const router = useRouter();
  const isFaqPage = router.pathname.includes(Path.faq.root);

  const faqPageStyle = isDesktopMax && isFaqPage;

  return (
    <div className={cn(styles.wrapper, { [styles.wrapper__faqPage]: isFaqPage })}>
      <div className={styles.cardRow}>
        {!isFaqPage && (
          <div className={styles.questionAnswerCard}>
            <div className={styles.questionAnswerTop}>
              <div className={styles.title}>Есть вопрос?</div>
              <div className={styles.text}>У нас уже готов ответ</div>
            </div>
            <Link href={Path.faq.root}>
              <Button type='button' classNames={{ root: styles.btn__root }} color='indigo.5'>
                Узнать
              </Button>
            </Link>
          </div>
        )}

        <div className={cn(styles.mobAppCard, { [styles.mobAppCard__faqPage]: faqPageStyle })}>
          {isMobile && <PCLogo />}
          {faqPageStyle && (
            <Image
              className={styles.mobAppCard__faqPage__phone}
              src='/assets/img/footer/pc_desktop_phone.png'
              alt='Not found'
              width={550}
              height={550}
            />
          )}
          <div className={cn(styles.mobAppCard__textblock, { [styles.mobAppCard__faqPage__textbloc]: faqPageStyle })}>
            {!faqPageStyle ? (
              <div className={styles.mobAppCard__title}>
                <span className={styles.mobAppCard__title__mobile}>Мобильное</span>
                <span className={styles.mobAppCard__title__app}>приложение</span>
              </div>
            ) : (
              <div className={styles.mobAppCard__faqPage__title}>Мобильное приложение</div>
            )}
            <div className={styles.mobAppCard__info}>
              <span>Управляй связью</span>
            </div>
          </div>
          <Link href={Path.faq.app} className={styles.mobAppBtn}>
            <Button type='button' classNames={{ root: styles.mobAppBtn__root }}>
              Перейти
            </Button>
          </Link>
        </div>
      </div>

      <div className={styles.socialBlock}>
        <div className={styles.socialRow}>
          <Link target='_blank' href={LINKS.VK}>
            <VkIcon />
          </Link>
          <Link target='_blank' href={LINKS.YOUTUBE}>
            <YouTubeIcon />
          </Link>
          <Link target='_blank' href={LINKS.TELEGRAM}>
            <TelegramIcon />
          </Link>
          <Link target='_blank' href={LINKS.DZEN}>
            <DzenIcon />
          </Link>
        </div>
      </div>
    </div>
  );
});
FooterCardsBlock.displayName = 'FooterCardsBlock';
