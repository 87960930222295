export const CITY_LIST = [
  'Севастополь',
  'Симферополь',
  'Керчь',
  'Ялта',
  'Евпатория',
  'Феодосия',
  'Судак'
];

export const LOCATION_LIST = [
  'Алушта',
  'Бондаренково',
  'Верхняя Кутузовка',
  'Виноградное',
  'Генеральское',
  'Запрудное',
  'Зеленогорье',
  'Изобильное',
  'Кипарисное',
  'Лаванда',
  'Лавровое',
  'Лазурное',
  'Лучистое',
  'Малореченское',
  'Малый Маяк',
  'Нижнее Запрудное',
  'Нижняя Кутузовка',
  'Партенит',
  'Приветное',
  'Пушкино',
  'Розовый',
  'Рыбачье',
  'Семидворье',
  'Солнечногорское',
  'Утёс',
  'Чайка',
  'Армянск',
  'Волошино',
  'Перекоп',
  'Суворово',
  'Джанкой',
  'Евпатория',
  'Заозёрное',
  'Мирный',
  'Новоозёрное',
  'Керчь',
  'Красноперекопск',
  'Саки',
  'Аграрное',
  'Аэрофлотский',
  'Битумное',
  'Грэсовский',
  'Комсомольское',
  'Симферополь',
  'Богатовка',
  'Весёлое',
  'Ворон',
  'Громовка',
  'Грушевка',
  'Дачное',
  'Лесное',
  'Междуречье',
  'Миндальное',
  'Морское',
  'Новый Свет',
  'Переваловка',
  'Прибрежное',
  'Солнечная Долина',
  'Судак',
  'Холодовка',
  'Береговое',
  'Ближнее',
  'Виноградное',
  'Коктебель',
  'Краснокаменка',
  'Курортное',
  'Наниково',
  'Насыпное',
  'Орджоникидзе',
  'Пионерское',
  'Подгорное',
  'Приморский',
  'Солнечное',
  'Степное',
  'Узловое',
  'Феодосия',
  'Щебетовка',
  'Южное',
  'Алупка',
  'Береговое',
  'Виноградное',
  'Восход',
  'Высокогорное',
  'Гаспра',
  'Голубой Залив',
  'Горное',
  'Гурзуф',
  'Даниловка',
  'Кацивели',
  'Кореиз',
  'Краснокаменка',
  'Куйбышево',
  'Курпаты',
  'Ливадия',
  'Линейное',
  'Массандра',
  'Никита',
  'Олива',
  'Оползневое',
  'Ореанда',
  'Отрадное',
  'Охотничье',
  'Парковое',
  'Партизанское',
  'Понизовка',
  'Санаторное',
  'Симеиз',
  'Советское',
  'Форос',
  'Ялта'
];
