import { SingleMedia, StrapiCollectionResponse, StrapiSingleTypeResponse } from '@/shared/types/strapi.types';
import { Prices } from '@/modules/tariff/shared/constants';
import { TariffHomeInternetEntity } from '../tariff-home-internet/tariff-home-internet.type';
import { TariffMobileEntity } from '../tariff-mobile/tariff-mobile.type';
import { Service } from '../service/service.type';

export enum HOME_INTERNET_SERVICE_TYPE {
  SPEED = 'speed',
  TV = 'tv',
  SUBSCRIPTION = 'subscription',
  EQUIPMENT = 'equipment',
  ADDITIONAL = 'additional',
  SALE = 'sale',
}

export enum BUILDING_TYPE {
  APARTMENT = 'apartment',
  HOUSE = 'house',
}

export interface ServiceHomeInternet {
  name: string;
  localized_name: string;
  background_image: SingleMedia;
  description: string;
  shpd_id: number;
  promotion_description: string;
  promotion_period: string;
  building_type: BUILDING_TYPE;
  service_type: HOME_INTERNET_SERVICE_TYPE;
  tariffs: TariffHomeInternetEntity;
  prices: Prices;
  affects_promotion: TariffMobileEntity;
  default_tariff_home_internets: TariffHomeInternetEntity;
  priority: number;
  order_with_mobile_tariff: boolean;
  service: StrapiSingleTypeResponse<Service>
}

export type ServiceHomeInternetEntity = StrapiCollectionResponse<ServiceHomeInternet>;
