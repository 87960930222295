import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { AboutCompanyEntity } from './about-company.type';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { StrapiQueryParams } from '@/shared/types/strapi.types';

export const aboutCompanyApi = createApi({
  reducerPath: 'aboutCompanyApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.aboutCompany),

  endpoints: (builder) => ({
    getAboutCompany: builder.query<AboutCompanyEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetAboutCompanyQuery } = aboutCompanyApi;
