import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  fontFamily: 'Roboto',
  components: {
    Accordion: {
      styles: {
        control: {
          '&:last-of-type': {
            '&[data-accordion-control]': {
              '&[data-active]': {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            },
          },
        },
      },
    },
    Carousel: {
      styles: {
        control: {
          height: '32px',
          width: '32px',
        },
      },
    },
  },
};

export const MAIN_TITLE = 'Мобильная связь в Крыму - сотовый оператор "Волна мобайл"Мобильная связь в Крыму - сотовый оператор "Волна мобайл"';
