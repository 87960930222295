export const initialize = () => {
  window.chat24_token = '802c366a0e8e6dbec6401cc0ed825a51';
  window.chat24_url = 'https://livechatv2.chat2desk.com';
  window.chat24_socket_url = 'wss://livechatv2.chat2desk.com/widget_ws_new';
  window.chat24_show_new_wysiwyg = 'true';
  window.chat24_static_files_domain = 'https://storage.chat2desk.com/';
  window.lang = 'ru';

  const baseUrl = window.chat24_url;
  const endpoint = '/packs/manifest.json';
  const nocacheParam = `?nocache=${new Date().getTime()}`;

  window
    .fetch(`${baseUrl}${endpoint}${nocacheParam}`)
    .then(function (res) {
      return res.json();
    })
    .then(function (data) {
      const chat24 = document.createElement('script');
      chat24.type = 'text/javascript';
      chat24.async = true;
      chat24.src = ''.concat(baseUrl).concat(data['application.js']);
      document.body.appendChild(chat24);
    });
};
