import { useOutsideHandler } from '@/shared/lib/use-outside-click-handler';
import React, { useRef, useState, KeyboardEvent, useEffect } from 'react';
import { ResponseEntity } from '@/shared/types/strapi.types';
import { FaqType } from '@/store/api/faq/faq.type';

interface SearchInputOptions {
  onClose?: () => void;
  onClickOutside?: () => void;
  value?: string;
  onChange?(value: string): void;
  onInput?(value: string): void;
  onEnterPress?(): void;
  data?: ResponseEntity<FaqType>[] | undefined;
}
export const useSearchInput = ({ value = '', onChange, onInput, onEnterPress, onClickOutside }: SearchInputOptions) => {
  const [value_, setValue] = useState<string | undefined>(value);
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const validateInput = (input: string) => {
    let inputCheck = input.replace(/^\s+/, '').replace(/\s{2,}/g, ' ');

    inputCheck = inputCheck.slice(0, 256);

    inputCheck = inputCheck.replace(/[^a-zA-Z0-9а-яА-ЯёЁ ,.-]+/g, '');

    return inputCheck;
  };

  const handleChange = (value: string) => {
    const validatedValue = validateInput(value);
    setValue(validatedValue);
    onChange?.(validatedValue);
    validatedValue !== '' ? setShowCloseButton(true) : setShowCloseButton(false);
  };

  const handleInput: React.FormEventHandler<HTMLInputElement> = (event) => {
    const newValue = (event.target as HTMLInputElement).value;
    const validatedValue = validateInput(newValue);
    setValue(validatedValue);
    onInput?.(validatedValue);
    setShowCloseButton(validatedValue !== '');
  };

  const handleMouseEnter = () => {
    if (value_) {
      setShowCloseButton(true);
    }
  };

  const handleMouseLeave = () => {
    setShowCloseButton(false);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress();
    }
  };

  const onClear = () => {
    setValue('');
    onChange?.('');
    setShowCloseButton(false);
  };

  useOutsideHandler(wrapperRef, (target) => {
    if (!target?.closest('.mantine-Autocomplete-dropdown')) {
      onClickOutside?.();
    }
  });

  return {
    wrapperRef,
    handleMouseEnter,
    handleMouseLeave,
    showCloseButton,
    onClear,
    value_,
    handleInput,
    handleChange,
    handleKeyUp,
  };
};
