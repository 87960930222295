import { Path } from '@/shared/constants/links';

function findChatButton() {
  const widget = document.querySelector('#chat24-root chat-24');
  // const el = widget?.shadowRoot?.querySelector('.widget'); - для десктопа

  return widget?.shadowRoot?.querySelector('.startBtn');
}

function findWidgetElement() {
  const widget = document.querySelector('#chat24-root chat-24');

  return widget?.shadowRoot?.querySelector('.widget');
}

export function fixButtonPosition() {
  const el = findChatButton();
  if (el) {
    const location = window.location.pathname;
    if (location.includes(Path.tariffMobile.root)) {
      (el as HTMLElement).style.bottom = '124px';
    } else if (location.includes(Path.tariffHomeInternet.root)) {
      (el as HTMLElement).style.bottom = '92px';
    } else {
      (el as HTMLElement).style.bottom = '105px';
    }
  }
}

export function toggleWidgetVisibility(visible: boolean) {
  const widgetEl = findWidgetElement();
  const buttonEl = findChatButton();
  for (const el of [widgetEl, buttonEl]) {
    if (el) {
      (el as HTMLElement).style.transition = 'opacity 0.5s';
      if (visible) {
        (el as HTMLElement).style.opacity = '1';
        (el as HTMLElement).style.pointerEvents = 'all';
      } else {
        (el as HTMLElement).style.opacity = '0';
        (el as HTMLElement).style.pointerEvents = 'none';
      }
    }
  }
}
