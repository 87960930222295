import React, { FC, ReactNode } from 'react';
import { Slider as MantineSlider, SliderProps as MantineSliderProps } from '@mantine/core';
import styles from './slider.module.scss';
import cn from 'classnames';

interface SliderProps extends MantineSliderProps {
  rightLabel?: ReactNode;
  leftLabel?: ReactNode;
  className?: string;
  blueBg?: boolean;
}

export const Slider: FC<SliderProps> = ({ rightLabel, leftLabel, className, blueBg, ...props }) => (
  <div className={cn(styles.container, blueBg && styles.blue, className && className)}>
    <div className={styles.labels}>
      <div className={styles.labels__left}>{leftLabel}</div>
      <div className={styles.labels__right}>{rightLabel}</div>
    </div>
    <MantineSlider
      {...props}
      classNames={{
        root: styles.root,
        track: styles.track,
        trackContainer: styles.track__container,
        label: styles.label,
        bar: styles.bar,
        thumb: styles.thumb,
        mark: styles.mark,
        marksContainer: styles.mark__container,
        markFilled: styles.mark__filled,
        markLabel: styles.mark__label,
        markWrapper: styles.mark__wrapper,
      }}
    />
  </div>
);
