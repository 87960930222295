import { FC, useState } from 'react';
import { CloseBtn } from '@/shared/assets/icons';
import { volnaTheme as theme } from '@/shared/constants/theme';
import cn from 'classnames';
import styles from './currency-input.module.scss';
import { Input, InputProps } from '@mantine/core';
import { IMask, IMaskInput } from 'react-imask';
import { ErrorIcon } from '@/shared/assets/svg';

interface CurrencyInputProps extends InputProps {
  onChange?: (unmasked: string, masked?: string) => void;
  darkBackground?: boolean;
  label?: string;
  mask?: string;
  placeholder?: string;
  initialValue?: string;
}

export const CurrencyInput: FC<CurrencyInputProps> = ({
  onChange: changeHandler,
  darkBackground,
  error,
  disabled,
  mask,
  label,
  placeholder = '10 ₽ - 15000 ₽',
  initialValue,
  className,
  ...props
}) => {
  const [value, setValue] = useState(initialValue || '');
  const [isActive, setIsActive] = useState(false);
  const customMask = mask
    ? { mask: mask }
    : {
        mask: 'num ₽',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            expose: true,
            thousandsSeparator: ' ',
            min: 0,
            max: 15000,
          },
        },
      };
  const masked = IMask.createMask([{ mask: '' }, customMask]);

  const onChange = (val: string) => {
    changeHandler && changeHandler(masked.unmaskedValue, val);
    setValue(val);
  };

  const onClear = () => {
    setValue('');
    masked.value = '';
    changeHandler && changeHandler(masked.unmaskedValue);
  };

  return (
    <Input.Wrapper
      classNames={{
        root: cn(styles.wrapper__root, darkBackground && styles.dark, className && className),
        label: styles.wrapper__label,
        error: styles.wrapper__error,
      }}
      error={error}
      label={label}
    >
      <Input
        classNames={{
          input: styles.input__input,
          rightSection: styles.input__right_section,
          wrapper: styles.input__wrapper,
        }}
        disabled={disabled}
        error={error}
        value={value}
        placeholder={placeholder}
        onInput={(event) => onChange(event.currentTarget.value)}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        component={IMaskInput}
        mask={masked}
        rightSectionWidth={50}
        rightSection={
          error ? (
            <ErrorIcon />
          ) : (
            <button
              type='button'
              onClick={onClear}
              className={cn(styles.closeBtn, { [styles.visible]: value && isActive })}
            >
              <CloseBtn color={darkBackground ? theme.colors.base[200] : theme.colors.base[400]} />
            </button>
          )
        }
        {...props}
      />
    </Input.Wrapper>
  );
};
