import { useMediaQuery as useMediaQueryMantine } from '@mantine/hooks';
import { onlyMobile, tablet, tabletOnly, desktop, desktopMax } from '../constants/media-queries';

export const useMediaQuery = () => {
  const isMobile = useMediaQueryMantine(onlyMobile);
  const isTablet = useMediaQueryMantine(`(min-width: ${tablet}px) and (max-width: ${tabletOnly}px)`);
  const isDesktop = useMediaQueryMantine(desktop);
  const isDesktopMax = useMediaQueryMantine(desktopMax);

  return { isMobile, isTablet, isDesktop, isDesktopMax };
};
