import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';

interface LinkProps {
  href: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children: ReactNode;
  className?: string;
}

export default function Link({ href, onClick, className, children }: LinkProps) {
  const router = useRouter();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClick && onClick(event);

    router.push(href);
  };

  return (
    <a href={href} className={className} onClick={handleClick}>
      {children}
    </a>
  );
}
