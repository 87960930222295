import { FC, memo } from 'react';
import Link from 'next/link';
import { ScrollArea } from '@mantine/core';
import cn from 'classnames';
import styles from './link-pills.module.scss';

interface LinkPill {
  value: string;
  label: string;
}

interface LinkPillsProps {
  data: LinkPill[];
  disabled?: boolean;
  value: string;
  className?: string;
}

export const LinkPills: FC<LinkPillsProps> = memo(({ data, value: active, disabled, className }) => {
  return (
    <ScrollArea
      w='100%'
      type='always'
      offsetScrollbars
      scrollbarSize='8px'
      classNames={{ scrollbar: styles.scroll__bar, thumb: styles.scroll__thumb }}
    >
      <ul className={cn(styles.container, className)}>
        {data.map(({ value, label }) => (
          <li
            key={`${label}-${value}`}
            className={cn(styles.item, {
              [styles.item__active]: value.split('/').pop() === active && !disabled,
              [styles.item__disabled]: disabled,
            })}
          >
            <Link
              href={value}
              className={cn(styles.link, {
                [styles.link__active]: value.split('/').pop() === active && !disabled,
                [styles.link__disabled]: disabled,
              })}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </ScrollArea>
  );
});

LinkPills.displayName = 'LinkPills';
