import { createApi } from '@reduxjs/toolkit/query/react';
import { StrapiQueryParams, StrapiSingleTypeResponse } from '@/shared/types/strapi.types';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { apiEndpoints } from '@/shared/constants/links';
import { TariffMobile, TariffMobileEntity } from './tariff-mobile.type';

export const tariffApi = createApi({
  reducerPath: 'tariffApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.tariffs),
  endpoints: (builder) => ({
    getMobileTariffs: builder.query<TariffMobileEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
    getMobileTariff: builder.query<StrapiSingleTypeResponse<TariffMobile>, { params: StrapiQueryParams; id: number }>({
      query: ({ params, id }) => {
        return {
          url: `/${id}` + addingParameters({ params }),
        };
      },
    }),
  }),
});

export const { useGetMobileTariffsQuery, useGetMobileTariffQuery } = tariffApi;
