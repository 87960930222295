import { FC } from 'react';
import cn from 'classnames';
import { SegmentedControl } from '@mantine/core';
import { BUILDING_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { BuildingTypeData } from '@/widgets/home-internet/constants';
import styles from './building-tabs.module.scss';

interface BuildingTabsProps {
  onChange: (val: BUILDING_TYPE) => void;
  value: BUILDING_TYPE;
  pills?: boolean;
}

export const BuildingTabs: FC<BuildingTabsProps> = ({ value, onChange, pills }) => {
  return (
    <SegmentedControl
      classNames={{
        root: cn(styles.root, { [styles.root__pills]: pills }),
        label: cn(styles.label, { [styles.label__pills]: pills }),
        indicator: cn(styles.indicator, { [styles.indicator__pills]: pills }),
      }}
      data={BuildingTypeData}
      onChange={onChange}
      value={value}
    />
  );
};
