import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { apiEndpoints } from '@/shared/constants/links';
import { PaymentData, PaymentError, PaymentArgs, CheckOperatorResponse } from './payment.type';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.paymentAdapter),
  endpoints: (builder) => ({
    addPaymentTransaction: builder.mutation<PaymentData, PaymentArgs>({
      query: (data) => ({
        url: 'create_payment',
        method: 'POST',
        body: data,
      }),
    }),
    checkOperator: builder.query<CheckOperatorResponse, string>({
      query: (msisdn) => {
        return {
          url: `/check_msisdn/${msisdn}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useAddPaymentTransactionMutation, useCheckOperatorQuery } = paymentApi;
