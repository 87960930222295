import { HOME_INTERNET__CONSTANTS, SLIDER_VALUE } from '@/widgets/home-internet/constants';

export const tariffIdentifiers = {
  'Могу себе позволить': { trpl_id: 103, trpl_name: 'Могу себе позволить', bil_trpl_id: 1808 },
  'Сделай сам': { trpl_id: 106, trpl_name: 'Сделай сам' },
  Стартуй: { trpl_id: 101, trpl_name: 'Стартуй', bil_trpl_id: 1806 },
  Ветер: { trpl_id: 7, trpl_name: 'Ветер' },
  Летай: { trpl_id: 102, trpl_name: 'Летай', bil_trpl_id: 1807 },
  Загорай: { trpl_id: 107, trpl_name: 'Загорай' },
};

export const serviceIdentifiers = {
  [HOME_INTERNET__CONSTANTS.SBERBOX]: 1885,
  [HOME_INTERNET__CONSTANTS.VIJU]: 1826,
  [HOME_INTERNET__CONSTANTS.WIFI]: 1881,
  [SLIDER_VALUE.VALUE_3]: {
    speed: 1823,
    tv: 1820,
  },
  [SLIDER_VALUE.VALUE_2]: {
    speed: 1822,
    tv: 1819,
  },
  [SLIDER_VALUE.VALUE_1]: {
    speed: 1821,
    tv: 1818,
  },
};

export const ORDER_PARAMS = {
  SOURCE_ID: 1, // 1 - Волна, 2 - +7
  PAYMENT_SYSTEM_ID: 1,
  ORDER_FLOW_TYPE: 1, // 1 - для физической сим карты, 2 - для esim
};

export enum TYPE_DELIVERY_ID {
  PICKUP = 1,
  DELIVERY = 2,
}

export enum PAYMENT_TYPE {
  ONLINE = 1,
  OFFLINE = 2,
}

export enum ITEM_TYPE_ID {
  TARIFF = 1,
  BEAUTIFUL_NUMBER = 2,
  SERVICE = 3,
  PRODUCT = 4,
}

export const DELIVERY_ID = 1227;
