import { Comment, Currency, Dialpad, Pin, Sketch, New, Logo_2, Box_2 } from '@/shared/assets/svg';
import { Path } from '@/shared/constants/links';

export const categoryBtn = [
  {
    name: 'Частным лицам',
  },
  {
    name: 'Бизнесу',
    src: 'https://corp.volnamobile.ru/',
  },
];

export const sidebarItems: SideBarOption[] = [
  {
    Icon: Currency,
    Title: 'Пополнение счета',
    children: null,
    id: 1,
    src: Path.replenishment.root,
  },
  {
    Icon: Box_2,
    Title: 'Услуги',
    children: null,
    id: 2,
    src: Path.services.root,
  },
  {
    Icon: New,
    Title: 'Новости и акции',
    children: null,
    id: 3,
    src: Path.news,
  },
  {
    Icon: Pin,
    Title: 'Точки продаж',
    children: null,
    id: 4,
    src: Path.pointsOfSale,
  },
  {
    Icon: Sketch,
    Title: 'Красивые номера',
    children: null,
    id: 5,
    src: 'https://volnamobile.ru/numbers/',
  },
  {
    Icon: Comment,
    Title: 'Помощь и поддержка',
    id: 6,
    src: Path.faq.root,
  },
  {
    Icon: Logo_2,
    Title: 'О компании',
    id: 10,
    src: Path.aboutCompany,
    children: null,
  },
];

export interface SideBarOption {
  Icon: React.FC<{ className?: string }> | null;
  Title: string;
  children?: null | SideBarOption[];
  id: number;
  src?: string;
}
