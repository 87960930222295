import { createApi } from '@reduxjs/toolkit/query/react';
import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { apiEndpoints } from '@/shared/constants/links';
import { LandingEntity } from './landing.type';

export const landingApi = createApi({
  reducerPath: 'landingApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.landings),

  endpoints: (builder) => ({
    getLandings: builder.query<LandingEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetLandingsQuery, useLazyGetLandingsQuery } = landingApi;
