import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { SalesPointEntity } from './sales-points.type';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { addingParameters } from '@/shared/lib/adding-parameters';

export const salesPointsApi = createApi({
  reducerPath: 'salesPointsApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.salesPoints),

  endpoints: (builder) => ({
    getSalesPoints: builder.query<SalesPointEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetSalesPointsQuery } = salesPointsApi;
