import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Burger } from '@mantine/core';
import 'react-spring-bottom-sheet/dist/style.css';
import { BackArrow, Frame, Home, LogoIcon, MenuIcon, Phone, Simcard } from '@/shared/assets/svg';
import IconButton from './icon-button/icon-button';
import CategoryBtn from './category-btn/category-button';
import { categoryBtn } from './constants';
import { useSidebar } from './use-sidebar';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import Link from 'next/link';
import { LINKS } from '@/shared/constants/links';
import { Tooltip } from '@/shared/ui-kit/tooltip/tooltip';
import { Location } from '@/widgets/location';
import { useLocation } from '@/widgets/location/use-location';
import { YM } from '@/shared/constants/yandex-metrika';
import { reachGoal } from '@/shared/lib/ym';
import { useOutsideHandler } from '@/shared/lib/use-outside-click-handler';
import { Path } from '@/shared/constants/links';
import styles from './sidebar.module.scss';

interface SidebarProps {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

const Sidebar: React.FC<SidebarProps> = React.memo((props) => {
  const { isOpen, onOpen, onClose } = props;
  const router = useRouter();
  const { isMobile, isDesktopMax } = useMediaQuery();
  const {
    activeItem,
    activeCategory,
    prevSidebarState,
    isFullSidebar,
    sidebarOptions,
    handleOpenSideBar,
    handleChangeCategory,
    handleCloseSideBar,
    handleBackPrevState,
    handleGoInto,
  } = useSidebar({
    isOpen,
    onClose,
    onOpen,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideHandler(wrapperRef, (target) => {
    if (!target?.closest('.wrapper')) {
      handleCloseSideBar?.();
    }
  });

  const { locationModalOpened, location, handleLocationModalOpen, handleLocationModalClose } = useLocation();

  const renderOptions = sidebarOptions.map((el, idx) => {
    const onThisPage = router.pathname.includes(el.src || '');

    const button = (
      <Link key={idx} href={el.src || ''}>
        <IconButton
          onClick={handleGoInto(el.Title, el.children, el.id, el.src)}
          nestedOptions={el.children}
          classname={cn(styles.hoverIcon, onThisPage && styles.iconActiveColor)}
          active={onThisPage}
          visibleChildren={isOpen}
          showActiveLine={onThisPage}
          Icon={el.Icon}
        >
          {el.Title}
        </IconButton>
      </Link>
    );

    return isMobile || isOpen ? (
      button
    ) : (
      <Tooltip
        events={{ hover: true, focus: false, touch: false }}
        label={el.Title}
        position='right'
        key={el.Title}
        multiline={false}
        width={'auto'}
        opened={undefined}
      >
        {button}
      </Tooltip>
    );
  });

  const renderCategoryItems = categoryBtn.map((el, idx) => (
    <CategoryBtn
      onClick={handleChangeCategory(idx)}
      key={idx}
      active={idx === activeCategory || router.pathname.includes(el.src || '')}
      title={el.name}
      src={el?.src}
    />
  ));

  const renderBackButton = (
    <IconButton
      onClick={handleBackPrevState}
      classname={styles.hoverIcon}
      visibleChildren={isOpen}
      Icon={() => <BackArrow />}
    >
      {prevSidebarState.current.at(-1)?.name}
    </IconButton>
  );

  if (isMobile) {
    return (
      <>
        <Location opened={locationModalOpened} onClose={handleLocationModalClose} />
        <div onClick={handleCloseSideBar} className={cn(isOpen && styles.openBackground)} />
        <div className={styles.mobile}>
          <div className={styles.mobileIconsWrapper}>
            <IconButton
              isFirstIcon
              innerLabel='Меню'
              onClick={isOpen ? onClose : onOpen}
              Icon={MenuIcon}
              active={isOpen}
            />
            <Link href={Path.tariffMobile.root} onClick={() => reachGoal(YM.click_knopka_mobile)}>
              <IconButton onClick={onClose} innerLabel='Связь' Icon={Phone} />
            </Link>
            <Link href={Path.tariffHomeInternet.root} onClick={() => reachGoal(YM.click_knopka_home_internet)}>
              <IconButton onClick={onClose} innerLabel='Интернет' Icon={Home} />
            </Link>
            <Link href={LINKS.VOLNA_SALE}>
              <IconButton onClick={onClose} innerLabel='Волна Sale' Icon={Frame} />
            </Link>
            <Link href={LINKS.ESIM}>
              <IconButton onClick={onClose} innerLabel='eSIM' Icon={Simcard} />
            </Link>
          </div>
          <BottomSheet
            className={styles.bottomSheetStyles}
            blocking={false}
            onDismiss={handleCloseSideBar}
            open={isOpen}
          >
            <div className={styles.categoryWrapper}>{renderCategoryItems}</div>
            <div className={styles.contentMobile}>
              <div className={cn(isOpen && styles.contentVisible)}>
                {Boolean(prevSidebarState.current.length) && renderBackButton}
                {renderOptions}
              </div>
              {/* {isFullSidebar && (
                <div className={styles.footer}>
                  <IconButton
                    classname={styles.hoverIcon}
                    visibleChildren={isOpen}
                    Icon={() => <Pin_2 />}
                    onClick={handleLocationModalOpen}
                  >
                    {location}
                  </IconButton>
                </div>
              )} */}
            </div>
          </BottomSheet>
        </div>
      </>
    );
  }

  return (
    <>
      <Location opened={locationModalOpened} onClose={handleLocationModalClose} />
      <div
        ref={wrapperRef}
        onClick={handleCloseSideBar}
        className={cn(styles.wrapper, isOpen ? styles.openBackground : styles.closedBackground)}
      >
        <div onClick={(e) => e.stopPropagation()} className={cn(styles.closed, isOpen && styles.opened)}>
          <div className={styles.controlBlock}>
            <IconButton
              visibleChildren={isOpen}
              onClick={isOpen ? handleCloseSideBar : handleOpenSideBar}
              Icon={() => <Burger opened={isOpen} />}
            >
              <div className={styles.logoIcon}>
                <LogoIcon />
              </div>
            </IconButton>
          </div>
          {isFullSidebar && <div className={styles.categoryWrapper}>{renderCategoryItems}</div>}

          <div className={cn(styles.content, isOpen && styles.contentVisible)}>
            {Boolean(prevSidebarState.current.length) && renderBackButton}
            {isDesktopMax || isOpen ? renderOptions : null}
          </div>

          {/* {isFullSidebar && (
            <div className={styles.footer}>
              <IconButton
                classname={styles.hoverIcon}
                visibleChildren={isOpen}
                Icon={() => <Pin_2 />}
                onClick={handleLocationModalOpen}
              >
                {location}
              </IconButton>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
});

Sidebar.displayName = 'Sidebar';
export { Sidebar };
