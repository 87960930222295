import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { StrapiCollectionResponse } from '@/shared/types/strapi.types';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { FaqType, FaqCategory } from './faq.type';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { StrapiQueryParams } from '@/shared/types/strapi.types';

export const faqApi = createApi({
  reducerPath: 'faqApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.faq),

  endpoints: (builder) => ({
    getFaq: builder.query<StrapiCollectionResponse<FaqType>, string>({
      query: (queryString) => {
        return '/' + `?filters[info][title][$containsi]=${queryString}&populate=info&pagination[pageSize]=5`;
      },
    }),
  }),
});

export const { useGetFaqQuery } = faqApi;

//$startsWithi - фильтрация по началу строки

export const faqCategoryApi = createApi({
  reducerPath: 'faqCategoryApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.faqCategories),

  endpoints: (builder) => ({
    getFaqCategory: builder.query<StrapiCollectionResponse<FaqCategory>, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetFaqCategoryQuery } = faqCategoryApi;
