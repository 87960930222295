import React, { FC, ReactNode } from 'react';
import { Slider as MantineSlider, SliderProps as MantineSliderProps } from '@mantine/core';
import cn from 'classnames';
import Info from '@/shared/assets/svg/info-small.svg';
import { RichText, Tooltip } from '..';
import styles from './slider-diy.module.scss';

interface SliderProps extends MantineSliderProps {
  label?: ReactNode;
  className?: string;
  pink?: boolean;
  description?: string;
}

export const SliderDiy: FC<SliderProps> = ({ label, className, pink, description, ...props }) => (
  <div className={cn(styles.container, { [styles.pink]: pink }, className && className)}>
    <div className={styles.header}>
      <div className={styles.text}>{label}</div>
      {description && (
        <Tooltip label={<RichText whiteText>{description}</RichText>}>
          <div className={styles.info}>
            <Info />
          </div>
        </Tooltip>
      )}
    </div>
    <MantineSlider
      {...props}
      classNames={{
        root: styles.root,
        track: styles.track,
        trackContainer: styles.track__container,
        label: styles.label,
        bar: styles.bar,
        thumb: styles.thumb,
        mark: styles.mark,
        marksContainer: styles.mark__container,
        markFilled: styles.mark__filled,
        markLabel: styles.mark__label,
        markWrapper: styles.mark__wrapper,
      }}
    />
  </div>
);
