import { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import styles from './banner.module.scss';

interface BannerProps {
  srcMobile?: string;
  srcTablet?: string;
  srcDesktop?: string;
  alt?: string;
  onClick?: () => void;
}

export const Banner: FC<BannerProps> = ({ srcMobile, srcTablet, srcDesktop, alt, onClick }) => {
  const { isMobile, isDesktop } = useMediaQuery();
  const [isReady, setIsReady] = useState(false);
  const myLoader = ({ src }: { src: string }) => src;
  const imageSrc = isMobile ? srcMobile : isDesktop ? srcDesktop : srcTablet;

  useEffect(() => {
    setIsReady(false);
  }, [srcMobile, srcTablet, srcDesktop, alt]);

  return (
    <div className={styles.wrapper} onClick={onClick}>
      {!isReady && <div className={styles.skeleton}></div>}
      {imageSrc ? (
        <Image
          src={imageSrc}
          loader={myLoader}
          alt={alt || 'Banner'}
          width={125}
          height={116}
          layout='responsive'
          onLoad={() => setIsReady(true)}
          priority
          unoptimized
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};
