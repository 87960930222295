import { createApi } from '@reduxjs/toolkit/query/react';
import { StrapiQueryParams, StrapiSingleTypeResponse } from '@/shared/types/strapi.types';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { apiEndpoints } from '@/shared/constants/links';
import { TariffHomeInternetCategory, TariffHomeInternetCategoryEntity } from './tariff-home-internet-catogory.type';

export const tariffHomeInternetCategoriesApi = createApi({
  reducerPath: 'tariffHomeInternetCategoriesApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.tariffHomeInternetCategories),

  endpoints: (builder) => ({
    getTariffHomeInternetCategories: builder.query<TariffHomeInternetCategoryEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
    getTariffHomeInternetCategory: builder.query<
      StrapiSingleTypeResponse<TariffHomeInternetCategory>,
      { params: StrapiQueryParams; id: number }
    >({
      query: ({ params, id }) => {
        return {
          url: `/${id}` + addingParameters({ params }),
        };
      },
    }),
  }),
});

export const { useGetTariffHomeInternetCategoriesQuery, useGetTariffHomeInternetCategoryQuery } =
  tariffHomeInternetCategoriesApi;
